<template>
  <div id="app">
    <div id="header">
      <baseNavbar />
    </div>
    <router-view id="main" />
    <baseFooter />
    <baseOverlay />
  </div>
</template>

<script>
import baseNavbar from '@/components/baseNavbar'
import baseFooter from '@/components/baseFooter'
import baseOverlay from '@/components/baseOverlay'

export default {
  components:{
    baseNavbar,
    baseFooter,
    baseOverlay
  },
  data(){
    return {
      window: {
        scrollY: 0,
        scroll: false,
      },
    }
  },
  methods:{
    async retrieveData(item){
      try{
        await this.$store.dispatch('retrieveData', item);
      }
      catch(error){
        console.log("Error", error);
      }
    },
  },
  mounted(){
      this.retrieveData('carosello');
      this.retrieveData('news');
      this.retrieveData('prodotti');
      this.retrieveData('servizi');
      this.retrieveData('contatti');
      this.retrieveData('posts');
      this.retrieveData('pages');
      this.$store.dispatch('getCookie', 'cookie-policy');
  },
}
</script>

<style lang="scss">

  @import "~@/scss/app.scss";
</style>
