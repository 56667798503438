<template>
  <baseItemsMenu name="servizi" child="ServicesDescription"/>
</template>

<script>
import baseItemsMenu from '@/components/baseItemsMenu'
export default {
  components:{
    baseItemsMenu
  }
}
</script>